import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
//  Link,
  useParams
} from "react-router-dom";


import './styles/LayoutContainer.css';
import TopBar from "./partials/TopBar";
import NavBar from "./partials/NavBar";
import SideBar from "./partials/SideBar";
import ArticleStory from "./layouts/ArticleStory";
//import ArticleList from "./partials/ArticleList";
import ListLayout from "./layouts/ListLayout";
import HomeLayout from "./layouts/HomeLayout";
import ArtistLayout from "./layouts/ArtistLayout";
import ContactLayout from "./layouts/ContactLayout";
import ConcertLayout from "./layouts/ConcertLayout";
import FestivalLayout from "./layouts/FestivalLayout";
import GuideLayout from "./layouts/GuideLayout";
import FestLayout from "./layouts/FestLayout";
import FestProfile from "./layouts/FestProfile";
import VenueLayout from "./layouts/VenueLayout";
import VenueProfile from "./layouts/VenueProfile";
import ConcertListing from "./layouts/ConcertListing";
import ContestLayout from "./layouts/ContestLayout";
import ContestEntry from "./layouts/ContestEntry";
import TrendingLayout from "./layouts/TrendingLayout";
import SearchLayout from "./layouts/SearchLayout";

//import getStoryData from "./epi/StoryData";





class LayoutContainer extends React.Component {
  render() {
    return (
      <div>
    <Router>
      <TopBar />
      <div className="app-wrap">

          <NavBar />

        <div className="container content-container">
          <div className="router-content">
            <router-outlet>

              <Switch>
                  <Route path="/artists/:slug" children={<ArtistChild />} />
                  <Route path="/search/:query" children={<SearchChild />} />
                  <Route path="/contact/:slug" children={<ContactChild />} />
                  <Route path="/guides" children={<GuideLayout />} />
                  <Route path="/fests/fest/:slug" children={<FestChild />} />
                  <Route path="/venues/venue/:slug" children={<VenueChild />} />
                  <Route path="/venues" children={<VenueLayout />} />
                  <Route path="/fests" children={<FestLayout />} />
                  <Route path="/contact">
                    <ContactLayout />
                  </Route>
                  <Route path="/contests/article/:slug" children={<ContestChild />} />
                  <Route path="/contests">
                    <ContestLayout />
                  </Route>

                  <Route path="/concerts">
                    <ConcertLayout />
                  </Route>
                  <Route path="/festivals">
                    <FestivalLayout />
                  </Route>
                  <Route path="/music/concert/:slug" children={<ListingChild />} />
                  <Route path="/article">
                    <ArticleStory />
                  </Route>
                  <Route path="/trending21">
                    <TrendingLayout />
                  </Route>
                 <Route path="/:section/article/:slug" exact children={<StoryChild />} />
                 <Route path="/:filter" exact children={<ListChild />} />
                 <Route path="/:filter/page/:page" exact children={<ListChild />} />
                 <Route path="/:section/:filter" exact children={<ListChild />} />
                 <Route path="/:section/:filter/page/:page" exact children={<ListChild />} />


                 <Route path="/">
                    <HomeLayout />
                 </Route>
               </Switch>

            </router-outlet>
          </div>
          <SideBar />
        </div>

      </div>
                   </Router>
      </div>
    )
  }
}

function StoryChild(){
  //let {section, slug} = useParams();
  let { slug } = useParams();
  return (<ArticleStory slug={slug}/>)
}
function FestChild(){
  //let {section, slug} = useParams();
  let { slug } = useParams();
  return (<FestProfile slug={slug}/>)
}
function VenueChild(){
  //let {section, slug} = useParams();
  let { slug } = useParams();
  return (<VenueProfile slug={slug}/>)
}
function ArtistChild(){
  //let {section, slug} = useParams();
  let { slug } = useParams();
  return (<ArtistLayout slug={slug}/>);
}
function SearchChild(){
  //let {section, slug} = useParams();
  let { query } = useParams();
  return (<SearchLayout query={query}/>);
}

function ContestChild(){
  let {slug} = useParams();
  return (<ContestEntry slug={slug}/>);
}
function ContactChild(){
  let {slug} = useParams();
  return (<ContactLayout slug={slug}/>);
}


function ListChild(){
  let {section, filter, page} = useParams();
  return (<ListLayout section={section} filter={filter} page={page}/>);
}
function ListingChild(){
  let {slug} = useParams();
  return (<ConcertListing slug={slug}/>);
}

export default LayoutContainer
