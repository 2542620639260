/* eslint-disable */
import React from "react";
import {Helmet} from "react-helmet";
import Picks from "../partials/Picks";
import ArticleQuad from "../partials/ArticleQuad";
import { fixCards } from "../scripts/core.js";
import { articles as articles_trending } from "../../db/trending";
import { articles as articles_latest } from "../../db/latest";
import { articles as articles_features } from "../../db/features";
import { articles as articles_reviews } from "../../db/reviews";


class HomeLayout extends React.Component {
  state = {"trending":{"title":"Trending","articles":[]}, "latest":{"title":"Latest","articles":[]},"features":{"title":"Features","articles":[]},"reviews":{"title":"Reviews","articles":[]}};
  render() {
    return (
        <div>
          <Helmet>
            <title>Exclaim! | Canada's Authority on Music, Film and Entertainment</title>
            <meta name="twitter:title" content="Exclaim! | Canada's Authority on Music, Film and Entertainmen" />
            <meta property="og:title" content="Exclaim! | Canada's Authority on Music, Film and Entertainmen" />
            <link rel="canonical" href="https://exclaim.ca" />
            <meta property="og:url" content="https://exclaim.ca" />
            <meta name="keywords" content="metal-hardcore, pop-rock, music, news" />

            <meta name="description" content="Your daily dose of the best music, film and comedy news, reviews, streams, concert listings, interviews and other exclusives on Exclaim!" />
            <meta property="og:description" content="Your daily dose of the best music, film and comedy news, reviews, streams, concert listings, interviews and other exclusives on Exclaim!" />
            <meta name="twitter:description" content="Your daily dose of the best music, film and comedy news, reviews, streams, concert listings, interviews and other exclusives on Exclaim!" />

            <meta property="og:image" content="http://exclaim.ca/assets/images/square_logo.jpg" />
            <meta property="og:image:secure_url" content="https://exclaim.ca/assets/images/square_logo.jpg" />
            <meta name="twitter:image" content="https://exclaim.ca/assets/images/square_logo.jpg" />
            <meta property="og:image:width" content="150" />
            <meta property="og:image:height" content="150" />
          </Helmet>
          <Picks />
          <ArticleQuad title={this.state["latest"].title} more_path={"/all/latest"} articles={this.state["latest"].articles} />
          <ArticleQuad title={"Trending"} more_path={"/trending21"} articles={this.state["trending"].articles} />
          <ArticleQuad title={this.state["features"].title} more_path={"/all/features"} articles={this.state["features"].articles} />
          <ArticleQuad title={this.state["reviews"].title} more_path={"/all/reviews"} articles={this.state["reviews"].articles} />
        </div>
    )
  }

  componentDidMount() {
    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
    });
    var slots = {
      "trending":{"title":"Trending Now","endpoint":"/trending?limit=4","tags":[]},
      "latest":{"title":"Latest Coverage","endpoint":"/article/filtered?limit=4","tags":[]},
      "features":{"title":"Music Features","endpoint":"/article/filtered/music?filter=features&limit=4","tags":["music","features"]},
      "reviews":{"title":"Music Reviews","endpoint":"/article/filtered/music?filter=reviews&limit=4","tags":["music","reviews"]}
    };
    Object.entries(slots).map(([slot,meta])=>{

     // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
      // if(meta.tags.length > 0){
      //   var url = "https://exclaim.ca/epi/article/filtered/"+meta.tags[0]+'?filter='+meta.tags[1]+'&limit=4';
      // }else{
      //   var url = "https://exclaim.ca/epi/article/filtered?limit=4";
      // }
      var url = "/epi"+meta.endpoint;
      fetch(url,{
        method: "get",
        headers: h})
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            var s = this.state;
            if(slot == 'trending'){
              s[slot] = {"articles":{"content":result},"title":meta.title};
            }else{
              s[slot] = {"articles":result.articles,"title":meta.title};
            }
            this.setState(s);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    });
  }

   componentDidUpdate(){
     console.log("Fixing cards");
     fixCards();
   }
}
export default HomeLayout
