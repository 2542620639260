/* eslint-disable */
import React from "react"
import ContactHome from "../partials/ContactHome";
import ContactPartnership from "../partials/ContactPartnership";
import ContactDistribution from "../partials/ContactDistribution";
import ContactPublished from "../partials/ContactPublished";
import ContactCoverage from "../partials/ContactCoverage";
import ContactAdvertising from "../partials/ContactAdvertising";
import ContactNav from "../partials/ContactNav";

import "../styles/ContactLayout.css";

class ContactLayout extends React.Component {

  render() {

    if(!this.props.slug){
      return (        
        <ContactHome />
      );
    }else if(this.props.slug == 'partnership'){
      return (
        <ContactPartnership />
      );
    }else if(this.props.slug == 'distribution'){
      return (
        <ContactDistribution />
      );
    }else if(this.props.slug == 'getpublished'){
      return (
        <ContactPublished />
      );
    }else if(this.props.slug == 'getcoverage'){
      return (
        <ContactCoverage />
      );
    }
    else if(this.props.slug == 'advertising'){
      return (
        <ContactAdvertising />
      );
    }
  }
}
export default ContactLayout
