/* eslint-disable */
import React from "react"
import Picks from "../partials/Picks";
import ArticleQuad from "../partials/ArticleQuad";
import { fixCards} from "../scripts/core.js";
// import { articles as articles_trending } from "../../db/trending";
// import { articles as articles_latest } from "../../db/latest";
// import { articles as articles_features } from "../../db/features";
// import { articles as articles_reviews } from "../../db/reviews";
//


class ArtistLayout extends React.Component {
  state = {"trending":{"title":"Trending","articles":[]}, "latest":{"title":"Latest","articles":[]},"features":{"title":"Features","articles":[]},"reviews":{"title":"Reviews","articles":[]}};
  render() {
    return (
        <div>

          <ArticleQuad title={this.state["latest"].title} more_path={"/all/latest"} articles={this.state["latest"].articles} />
          <ArticleQuad title={"Trending"} more_path={"/trending21"} articles={this.state["trending"].articles} />
        </div>
    )
  }

  constructor(props){
    super(props);

    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
    });
    var slots = {
      "trending":{"title":"Trending Now","endpoint":"/trending?limit=4","tags":[]},
      "latest":{"title":props.slug.replaceAll('_',' '),"endpoint":"/artist/"+props.slug+"?limit=12","tags":[]},
    };
    Object.entries(slots).map(([slot,meta])=>{

     // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
      // if(meta.tags.length > 0){
      //   var url = "https://exclaim.ca/epi/article/filtered/"+meta.tags[0]+'?filter='+meta.tags[1]+'&limit=4';
      // }else{
      //   var url = "https://exclaim.ca/epi/article/filtered?limit=4";
      // }
      var url = "/epi"+meta.endpoint;
      fetch(url,{
        method: "get",
        headers: h})
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            var s = this.state;
            if(slot == 'trending'){
              s[slot] = {"articles":{"content":result},"title":meta.title};
            }else{
              s[slot] = {"articles":result,"title":meta.title};
            }
            this.setState(s);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    });
  }

  componentDidMount() {

  }

  componentDidUpdate(){
    console.log("Fixing cards");
    fixCards();
  }
}
export default ArtistLayout
