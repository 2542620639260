/* eslint-disable */
import React from "react";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import ConcertList from "../partials/ConcertList";
import ArticleQuad from "../partials/ArticleQuad";
import ArticleList from "../partials/ArticleList";
import ListLayout from "./ListLayout";
import { format } from "date-fns";
import { articles as articles_trending } from "../../db/trending";
// import { events } from "../../db/concerts";
// | date:'MMM d YYYY, HH:mm'

class ConcertListing extends React.Component {
  state = {"trending":[]};

  render() {
    let posterPath = '';//this.state["event"].poster_path != "" && this.state["event"].poster_path != null ? this.state['event'].poster_path : '/assets/images/ex_concerts.png';
    let keys = '';//this.state["event"].keywords+', '+this.state["event"].canonical_name;

    return (
      <div className="main-container">
      {this.state.event?(
        <>
        <Helmet>
          <title>{this.state["event"].name} | Exclaim!</title>
          <meta name="twitter:title" content={this.state["event"].name+" | Exclaim!"} />
          <meta property="og:title" content={this.state["event"].name+" | Exclaim!"} />
          <link rel="canonical" href={"https://exclaim.ca/concerts/"+this.state["event"].slug} />
          <link rel="amphtml" href={"https://exclaim.ca/ampconcert/"+this.state["event"].slug} />
          <meta property="og:url" content={"https://exclaim.ca/concerts/"+this.state["event"].slug} />

          <meta name="keywords" content={keys+", concert, listing, tickets, location, door time"} />
          <meta name="description" content={this.state["event"].description} />
          <meta property="og:description" content={this.state["event"].description} />
          <meta name="twitter:description" content={this.state["event"].description} />
          <meta property="og:type" content="website" />


          <meta property="og:image" content={"http://exclaim.ca"+posterPath} />
          <meta property="og:image:secure_url" content={"http://exclaim.ca"+posterPath} />
          <meta name="twitter:image" content={"http://exclaim.ca"+posterPath} />
          <meta property="og:image:width" content="745" />
          <meta property="og:image:height" content="492" />

          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <div className="article-story">
          <h1>{this.state["event"].name}</h1>
          <h2>{ format(new Date(this.state["event"].doors_open_at),'MMM d yyyy, HH:mm')} @ {this.state["event"].venue.name}</h2>
          {this.state["event"].poster_path != "" && this.state["event"].poster_path != null &&
            <div className="article-img">
              <img
                src={"https://exclaim.ca" + this.state["event"].poster_path }/>
            </div>
          }
          <div className=" article-body"  dangerouslySetInnerHTML={{ __html: this.state["event"].tickets }} />
          <div className=" article-body"><Link className="more"
            href={this.state["event"].ticket_link}> Get Tickets <i className="material-icons">skip_next</i></Link></div>
          <div className=" article-body"  dangerouslySetInnerHTML={{ __html: this.state["event"].description }} />
          </div>
          <div>
            {this.state['map_address']?(
            <div className="listing-map">
                <iframe loading="lazy" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=Bell Centre,%20Montreal&amp;hl=es;z=14&amp;output=embed" width="100%" height="240" frameborder="0"></iframe>

                </div>
              ):(<></>)}
          </div>
          <br/>
          <ListLayout title="Concert Coverage" section="music" filter="concert" limit="7"/>
          <br/>
          <ArticleQuad title={"Trending"} more_path={"/trending21"} articles={this.state.trending} />
          </>
        ):(<></>)}
       </div>
    )
  }

  constructor(props){
    super(props);
    if(!props.slug || props.slug == null) return;

    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
   var url = "/epi/concert/"+props.slug;

   fetch(url,{
      method: "get",
      headers: h})
      .then(res => res.json())
      .then(
        (result) => {
          var s = this.state;
          s["event"] = result;
          //'1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)'
          //,'+result.venue.city.replaceAll(' ','%20') + ',Canada
          // var address = result.venue.address.replaceAll(' ','%20') + '('+result.venue.name.replaceAll(' ','%20')+')'
          var address = '1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)'

          s['map_address'] = address
          this.setState(s);
          this.posterPath = this.state["event"].poster_path != "" && this.state["event"].poster_path != null ? this.state['event'].poster_path : '/assets/images/ex_concerts.png';
          this.keys = this.state["event"].keywords+', '+this.state["event"].canonical_name;;
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
      url = "/epi/trending?limit=4";
      fetch(url,{
     method: "get",
     headers: h})
     .then(res => res.json())
     .then(
       (result) => {
         var s = this.state;
         s["trending"] = {"content":result};
         this.setState(s);
       },
       // Note: it's important to handle errors here
       // instead of a catch() block so that we don't swallow
       // exceptions from actual bugs in components.
       (error) => {
         this.setState({
           isLoaded: true,
           error
         });
       }
     )
  }

  componentDidMount() {

 }
}
export default ConcertListing
