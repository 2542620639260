/* eslint-disable */
import React from "react";
import {Helmet} from "react-helmet";
import ContestList from "../partials/ContestList";
import ArticleQuad from "../partials/ArticleQuad";
// import { contests } from "../../db/contests";



class ContestLayout extends React.Component {

  render() {
    return (
      <>
      {this.state?(
      <div className="main-container filtered-container">
        <Helmet>
          <title>Contests on Exclaim!</title>
          <meta name="twitter:title" content="Contests on Exclaim!" />
          <meta property="og:title" content="Contests on Exclaim!" />
          <link rel="canonical" href="https://exclaim.ca/contests" />
          <meta property="og:url" content="https://exclaim.ca/contests" />
          <meta name="keywords" content="contests, tickets, albums" />

          <meta name="description" content="Your daily dose of the best music, film and comedy news, reviews, streams, concert listings, interviews and other exclusives on Exclaim!" />
          <meta property="og:description" content="Your daily dose of the best music, film and comedy news, reviews, streams, concert listings, interviews and other exclusives on Exclaim!" />
          <meta name="twitter:description" content="Your daily dose of the best music, film and comedy news, reviews, streams, concert listings, interviews and other exclusives on Exclaim!" />

          <meta property="og:image" content="http://exclaim.ca/assets/images/square_logo.jpg" />
          <meta property="og:image:secure_url" content="https://exclaim.ca/assets/images/square_logo.jpg" />
          <meta name="twitter:image" content="https://exclaim.ca/assets/images/square_logo.jpg" />
          <meta property="og:image:width" content="150" />
          <meta property="og:image:height" content="150" />
        </Helmet>
        {this.state.contests && this.state.contests.length > 0 &&
          <ContestList title="Contests on Exclaim!" contests={this.state.contests} more_path="/contests" more_title="More Contests"/>
        }

        {this.state.trending && (
        <> <br/>
          <ArticleQuad title={"Trending"} more_path={"/trending21"} articles={this.state.trending} />
        </>
        )}
      </div>
      ):(<></>)}
      </>
    )
  }

  constructor(props){
    super(props);
    var h = new global.Headers({
      // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
      "Content-Type": "application/json"
    });
    // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;

    var url = "/epi/contest/list";
    fetch(url,{
       method: "get",
       headers: h})
    .then(res => res.json())
    .then(
       (result) => {
         console.log(result);
         var s = this.state;
         if(s==null){
           s = {};
         }
         s["contests"] = result;
         this.setState(s);
       },
       // Note: it's important to handle errors here
       // instead of a catch() block so that we don't swallow
       // exceptions from actual bugs in components.
       (error) => {
         this.setState({
           isLoaded: true,
           error
             });
       }
    )

    url = "/epi/trending?limit=4";
    fetch(url,{
   method: "get",
   headers: h})
   .then(res => res.json())
   .then(
     (result) => {
       var s = this.state;
       if(s==null){
         s = {};
       }
       s["trending"] = {"content":result};
       this.setState(s);
     },
     // Note: it's important to handle errors here
     // instead of a catch() block so that we don't swallow
     // exceptions from actual bugs in components.
     (error) => {
       this.setState({
         isLoaded: true,
         error
       });
     }
   )
  }

  componentDidMount() {



  }
}
export default ContestLayout
