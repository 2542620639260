/* eslint-disable */
import React from "react"
import {Link} from "react-router-dom"
import "../styles/ArticleList.css";
import ArticleItem from "./ArticleItem";

class ArticleList extends React.Component {
  render() {
    return (
      <div className="list">
        <h3 className="widget-title">{this.props.title}
        {this.props.more_path &&(
          <Link className="button fancy-button widget-arrow"
            to={this.props.more_path+(this.props.page?'/page/'+(parseInt(this.props.page)+1):'')}><i className="material-icons">skip_next</i></Link>
          )}
        </h3>
        <div className="article-item-list">
          <div className="container">

            {this.props.articles.map(article=>(
              <ArticleItem key={article.id} article={article}/>
            ))}
          </div>
        </div>
        {this.props.more_path &&(
          <Link className="more"
              to={this.props.more_path+(this.props.page?'/page/'+(parseInt(this.props.page)+1):'')}> { this.props.more_title ? this.props.more_title : "More "+this.props.title } <i className="material-icons">skip_next</i></Link>
        )}
      </div>
    )
  }


}
export default ArticleList
