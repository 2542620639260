/* eslint-disable */
import React, {Fragment} from "react";
import ArticleCard from "../partials/ArticleCard";
import ArticleSolo from "../partials/ArticleSolo";
// TODO: fix html parsing for body


class TrendingList extends React.Component {

  render() {
    return (
      <div className="quadrant ranked-list">
        <ArticleCard article={this.props.articles[0]} className="ranked-item solo top" ranked={1}/>
        <div className="container">
          <ArticleCard article={this.props.articles[1]} ranked={1}/>
          <ArticleCard article={this.props.articles[2]} ranked={1}/>
          <ArticleCard article={this.props.articles[3]} ranked={1}/>
          <ArticleCard article={this.props.articles[4]} ranked={1}/>
        </div>
        <ArticleCard article={this.props.articles[5]} className="ranked-item solo" ranked={1}/>
        <br/>
        <div className="container">
          <ArticleCard article={this.props.articles[6]} ranked={1}/>
          <ArticleCard article={this.props.articles[7]} ranked={1}/>
          <ArticleCard article={this.props.articles[8]} ranked={1}/>
          <ArticleCard article={this.props.articles[9]} ranked={1}/>
        </div>
        <ArticleCard article={this.props.articles[10]} className="ranked-item solo" ranked={1}/>
        <br/>
        <div className="container">
          <ArticleCard article={this.props.articles[11]} ranked={1}/>
          <ArticleCard article={this.props.articles[12]} ranked={1}/>
          <ArticleCard article={this.props.articles[13]} ranked={1}/>
          <ArticleCard article={this.props.articles[14]} ranked={1}/>
        </div>
        <div className="container">
          <ArticleCard article={this.props.articles[15]} ranked={1}/>
          <ArticleCard article={this.props.articles[16]} ranked={1}/>
          <ArticleCard article={this.props.articles[17]} ranked={1}/>
          <ArticleCard article={this.props.articles[18]} ranked={1}/>
        </div>
        <div className="container">
          <ArticleCard article={this.props.articles[19]} ranked={1}/>
          <ArticleCard article={this.props.articles[20]} ranked={1}/>
        </div>

      </div>
    )
  }
}
export default TrendingList
