import React from "react"
import ArticleList from "./ArticleList"
import Cover from "./Cover"
import ArticleSolo from "./ArticleSolo"
//import { articles as articles_latest } from "../../db/news";
//import { article } from "../../db/story";

class SideBar extends React.Component {
  //state = {'trending':{"title":"Loading","articles":articles_latest},'news':{"title":"Loading","articles":articles_latest},'reviews':{"title":"Loading","articles":articles_latest}};
  render() {
    return (
      <div className="sidebar">
        <Cover />
        {this.state && this.state.news && this.state.news.articles &&(
          <ArticleList articles={this.state.news.articles.content} title={this.state.news.title} more_path="/all/news"/>
        )}
        {this.state && this.state.trending && this.state.trending.articles &&(
        <ArticleSolo article={this.state.trending.articles.content[0]} more_path="/trending21" more_title="More Trending Articles"/>
        )}
        {this.state && this.state.reviews && this.state.reviews.articles &&(
        <ArticleList articles={this.state.reviews.articles.content} title={this.state.reviews.title} more_path="/music/reviews"/>
        )}
      </div>
    )
  }

  componentDidMount() {
    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
    });
    var slots = {
      "trending":{"title":"Trending Now","endpoint":"/trending?limit=1","tags":[]},
      "news":{"title":"News","endpoint":"/article/filtered/music?filter=news&limit=4","tags":[]},
      "reviews":{"title":"Music Reviews","endpoint":"/article/filtered/music?filter=reviews&limit=4","tags":["music","reviews"]}
    };
    Object.entries(slots).map(([slot,meta])=>{

     // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
      // if(meta.tags.length > 0){
      //   var url = "https://exclaim.ca/epi/article/filtered/"+meta.tags[0]+'?filter='+meta.tags[1]+'&limit=4';
      // }else{
      //   var url = "https://exclaim.ca/epi/article/filtered?limit=4";
      // }
      var url = "/epi"+meta.endpoint;

     // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
     // var url = "https://exclaim.ca/epi/article/filtered/"+meta.tags[0]+'?filter='+meta.tags[1]+'&limit=5';
      fetch(url,{
        method: "get",
        headers: h})
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            var s = this.state;
            if(s == null){
              s = {};
            }
            if(slot === 'trending'){
              s[slot] = {"articles":{"content":result},"title":meta.title};
            }else{
              s[slot] = {"articles":result.articles,"title":meta.title};
            }
            this.setState(s);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
        return slot;
    });
  }


}
export default SideBar
