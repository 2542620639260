/* eslint-disable */
import React from "react";
import {Helmet} from "react-helmet";
import FestivalList from "../partials/FestivalList";
import ArtistFill from "../partials/ArtistFill";
import { events as concerts_filtered } from "../../db/concerts";
import "../styles/ConcertList.css";
import "../styles/FestivalList.css";
import {
  Link,
} from "react-router-dom";


class FestivalLayout extends React.Component {
  //state = [];
  render() {
    return (
      <>
      {this.state?(
      <div className="main-container concerts-container">
        <Helmet>
          <title>Canadian Festivals | Festival Guide by Exclaim!</title>
          <meta name="twitter:title" content="Canadian Festivals | Festival Guide by Exclaim!" />
          <meta property="og:title" content="Canadian Festivals | Festival Guide by Exclaim!" />
          <link rel="canonical" href="https://exclaim.ca/festivals" />
          <meta property="og:url" content="https://exclaim.ca/festivals" />
          <meta name="keywords" content="festivals, music festivals, canada, canadian, concerts, listings" />

          <meta name="description" content="Festival listings and coverage from all across Canada." />
          <meta property="og:description" content="Festival listings and coverage from all across Canada." />
          <meta name="twitter:description" content="Festival listings and coverage from all across Canada." />

          <meta property="og:image" content="http://exclaim.ca/assets/images/ex_concerts.png" />
          <meta property="og:image:secure_url" content="https://exclaim.ca/assets/images/ex_concerts.png" />
          <meta name="twitter:image" content="https://exclaim.ca/assets/images/ex_concerts.png" />
          <meta property="og:image:width" content="150" />
          <meta property="og:image:height" content="150" />
        </Helmet>
        <div className="concerts-filters">
          <div className="concerts-filter-city">
            <a className="more" onClick={this.cityToggle}>Region<i className="material-icons">navigation</i></a>
          </div>
          <div className="concerts-filter-artist">
            <a className="more" onClick={this.artistToggle}>Artist<i className="material-icons">search</i></a>
          </div>
          <div className="concerts-selectors">
            <div className="concert-selector hide" id="region-selector">
              <div className="wrap regions">
                <label>Region
                    <Link to="/festivals/region/bc">BC</Link>
                    <Link to="/festivals/region/prairies">Prairies</Link>
                    <Link to="/festivals/region/ontario">Ontario</Link>
                    <Link to="/festivals/region/quebec">Quebec</Link>
                    <Link to="/festivals/region/maritimes">Maritimes</Link>
                </label>
              </div>
            </div>
            <div className="concert-selector hide" id="fest-artist-selector">
              <div className="wrap>">
                <form onSubmit={this.handleArtistSubmit}>
                  <div className="component"><ArtistFill id="festivals" /></div>
                  <div className="selector-go">
                    <input type="submit" value="Go" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


        <FestivalList title={"Canadian Music Festivals"} more_path={"/music/concerts"} more_title="More Concerts" events={this.state} />
      </div>
    ):(<></>)}
      </>
    )
  }

  handleArtistSubmit(e){
    e.preventDefault();
    console.log("ARTIST SUBMIT");
    var path = '/festivals/artist?artist='+encodeURIComponent(document.getElementById('artist-fill-festivals').value);
    this.props.navigate(path);
  }


  cityToggle(){
    console.log("CITY");
    var region = document.getElementById('region-selector');
    var artist = document.getElementById('fest-artist-selector');
    if(!artist.classList.contains('hide')){
        artist.classList.toggle('hide');
        //artist.style['pointer-events'] = 'none';
    }
    if(!region.classList.contains('hide')){
      //region.style['pointer-events'] = 'all';
    }
    region.classList.toggle('hide');
  }

  artistToggle(){
    console.log("ARTIST");
    var artist = document.getElementById('fest-artist-selector');
    var region = document.getElementById('region-selector');

    if(!region.classList.contains('hide')){
        region.classList.toggle('hide');
        //region.style['pointer-events'] = 'none';
    }
    if(!artist.classList.contains('hide')){
    //  artist.style['pointer-events'] = 'all';
    }
    artist.classList.toggle('hide');


  }

  constructor(props){
    super(props);
    // if(!this.props.slug) return;

    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;

   var url = "/epi/concert/list";
      fetch(url,{
      method: "get",
      headers: h})
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          let s = this.state;
          // s['events'] = result;
          this.setState(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {

  }
}
export default FestivalLayout
