/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import ConcertList from "../partials/ConcertList";
import MaterialDatePicker from "../partials/DatePicker";
import ArtistFill from "../partials/ArtistFill";
import CityFill from "../partials/CityFill";
import { events as concerts_filtered } from "../../db/concerts";
import "../styles/ConcertList.css";


class ConcertLayout extends React.Component {
  state = {};
  render() {
    return (
      <>
      {this.state.events && this.state.events.length > 0?(
      <div className="main-container concerts-container">
        <Helmet>
          <title>Canadian Concert Listings | Exclaim!</title>
          <meta name="twitter:title" content="Canadian Concert Listings | Exclaim!" />
          <meta property="og:title" content="Canadian Concert Listings | Exclaim!" />
          <link rel="canonical" href="https://exclaim.ca/concerts" />
          <meta property="og:url" content="https://exclaim.ca/concerts" />
          <meta name="keywords" content="concerts, listings, festivals, music festivals, canada, canadian" />

          <meta name="description" content="Live music listings for concerts all across Canada." />
          <meta property="og:description" content="Live music listings for concerts all across Canada." />
          <meta name="twitter:description" content="Live music listings for concerts all across Canada." />

          <meta property="og:image" content="http://exclaim.ca/assets/images/ex_concerts.png" />
          <meta property="og:image:secure_url" content="https://exclaim.ca/assets/images/ex_concerts.png" />
          <meta name="twitter:image" content="https://exclaim.ca/assets/images/ex_concerts.png" />
          <meta property="og:image:width" content="150" />
          <meta property="og:image:height" content="150" />
        </Helmet>
        <div className="concerts-filters">
          <div className="concerts-filter-city">
            <Link className="more" onClick={this.cityToggle}>City<i className="material-icons">navigation</i></Link>
          </div>
          <div className="concerts-filter-artist">
            <Link className="more" onClick={this.artistToggle}>Artist<i className="material-icons">search</i></Link>
          </div>
          <div className="concerts-selectors">
            <div className="concert-selector hide" id="city-selector">
              <div className="wrap>">
                <form onSubmit={this.handleCitySubmit}>
                  <div className="component"><CityFill id="concerts" /></div>
                  <div className="component"><MaterialDatePicker id="city"/></div>
                  <div className="selector-go">
                    <input type="submit" value="Go" id="city-go"/>
                  </div>
                </form>
              </div>
            </div>
            <div className="concert-selector hide" id="artist-selector">
              <div className="wrap>">
                <form onSubmit={this.handleArtistSubmit}>
                  <div className="component"><ArtistFill id="concerts" /></div>
                  <div className="component"><MaterialDatePicker id="artist"/></div>
                  <div className="selector-go">
                    <input type="submit" value="Go" id="artist-go" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ConcertList title={"Canadian Concerts"} more_path={"/music/concerts"} more_title="More Concerts" events={this.state} />
      </div>
    ):(<></>)}
      </>
    )
  }

  handleArtistSubmit(e){
    e.preventDefault();
    console.log("ARTIST SUBMIT");
    var path = '/concerts/artist?artist='+encodeURIComponent(document.getElementById('artist-fill-concerts').value);
    var date = document.getElementById('date-picker-inline-artist').value;
    if(date){
      date = date.replaceAll('/','-');
      path += '&date='+date;
    }
    this.props.navigate(path);
  }

  handleCitySubmit(e){
    e.preventDefault();
    console.log("CITY SUBMIT");
    var path = '/concerts/city?city='+encodeURIComponent(document.getElementById('city-fill-concerts').value);
    var date = document.getElementById('date-picker-inline-city').value;
    if(date){
      date = date.replaceAll('/','-');
      path += '&date='+date;
    }
    this.props.navigate(path);
  }

  cityToggle(){
    console.log("CITY");
    var city = document.getElementById('city-selector');
    var artist = document.getElementById('artist-selector');
    if(!artist.classList.contains('hide')){
        artist.classList.toggle('hide');
    }
    city.classList.toggle('hide');
  }

  artistToggle(){
    console.log("ARTIST");
    var artist = document.getElementById('artist-selector');
    var city = document.getElementById('city-selector');

    if(!city.classList.contains('hide')){
        city.classList.toggle('hide');
    }
    artist.classList.toggle('hide');

  }

  constructor(props){
    super(props);
    // if(!this.props.slug) return;

    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;

   var url = "/epi/concert/list";
      fetch(url,{
      method: "get",
      headers: h})
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          let s = this.state;
          // s['events'] = result;
          this.setState(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {

  }
}
export default ConcertLayout
