import React from "react"
import { Link } from "react-router-dom"
import '../styles/Cover.css';

class Cover extends React.Component {
  render() {
    return (
      <div className="quad cover">
        <h3 href="/all/on-the-cover"
          className="widget-title">On The Cover
          <Link to="/all/cover" className="button fancy-button widget-arrow"><i className="material-icons">skip_next</i></Link>
        </h3>

        <div className="cover-img">
          <img src="https://exclaim.ca/assets/images/covers/cover-spring-2021.jpg" alt="Exclaim! Cover"/>
        </div>


        <Link className="more" to="/all/cover">More Cover Stories<i className="material-icons">skip_next</i></Link>
      </div>
    )
  }
}
export default Cover
