export const picks = [
  {
    id: 4316,
    headline: "Japandroids Reveal Who Covered Them the Best",
    url:
      "https://exclaim.ca/music/article/japandroids_cover_song_be_forest_i_quit_girls",
    thumb: "/images/japdroids.jpg",
    external: null,
    finished_at: "2021-04-14T11:00:00.000-04:00",
    sort: 1,
    location: "slider",
    section: "global",
    contextual_copy: "Features",
    created_at: "2021-03-31T11:01:35.913-04:00",
    updated_at: "2021-03-31T13:00:48.973-04:00"
  },
  {
    id: 4317,
    headline: "'Last of Us' Is Filming in Calgary",
    url:
      "https://exclaim.ca/film/article/hbos_the_last_of_us_tv_series_to_film_in_calgary",
    thumb: "/images/last-of-us_2.jpg",
    external: null,
    finished_at: "2021-04-14T13:00:00.000-04:00",
    sort: 2,
    location: "slider",
    section: "global",
    contextual_copy: "News",
    created_at: "2021-03-31T13:00:43.637-04:00",
    updated_at: "2021-03-31T13:00:51.894-04:00"
  },
  {
    id: 4005,
    headline: "Godzilla vs. Kong, Bad Trip, Sugar Daddy",
    url: "https://exclaim.ca/film/reviews",
    thumb: "/images/godzilla-vs-kong.jpg",
    external: null,
    finished_at: "2022-07-21T17:25:00.000-04:00",
    sort: 3,
    location: "slider",
    section: "global",
    contextual_copy: "Film Reviews",
    created_at: "2020-07-07T17:28:10.554-04:00",
    updated_at: "2021-03-31T13:01:11.892-04:00"
  },
  {
    id: 4315,
    headline: "'SNL' Calls to \"Save 'Kim's Convenience'\" ",
    url: "https://exclaim.ca/comedy/article/snl_calls_to_save_kims_convenience",
    thumb: "/images/kims-convenience-2021.jpg",
    external: null,
    finished_at: "2021-04-13T14:28:00.000-04:00",
    sort: 4,
    location: "slider",
    section: "global",
    contextual_copy: "News",
    created_at: "2021-03-30T14:29:12.957-04:00",
    updated_at: "2021-03-31T13:01:14.135-04:00"
  }
];
