/* eslint-disable */
import React from "react";
import {Helmet} from "react-helmet";
import VenueList from "../partials/VenueList";
import ArtistFill from "../partials/ArtistFill";
// import { articles as concerts_filtered } from "../../db/news";
import "../styles/ConcertList.css";
import "../styles/FestivalList.css";
import {
  Link,
} from "react-router-dom";


class VenueLayout extends React.Component {
  //state = [];
  render() {
    return (
      <>
      {this.state && this.state.cities?(
      <div className="main-container concerts-container">
        <Helmet>
          <title>The Best Music Festivals In Canada, Curated By Exclaim!</title>
          <meta name="twitter:title" content="Canadian Festivals | Festival Profile Guide by Exclaim!" />
          <meta property="og:title" content="Canadian Festival Profiles | Festival Profile Guide by Exclaim!" />
          <link rel="canonical" href="https://exclaim.ca/fests" />
          <meta property="og:url" content="https://exclaim.ca/fest" />
          <meta name="keywords" content="festivals, music festivals, canada, canadian, concerts, listings" />

          <meta name="description" content="Festival listings and coverage from all across Canada." />
          <meta property="og:description" content="Festival listings and coverage from all across Canada." />
          <meta name="twitter:description" content="Festival listings and coverage from all across Canada." />

          <meta property="og:image" content="http://exclaim.ca/assets/images/ex_concerts.png" />
          <meta property="og:image:secure_url" content="https://exclaim.ca/assets/images/ex_concerts.png" />
          <meta name="twitter:image" content="https://exclaim.ca/assets/images/ex_concerts.png" />
          <meta property="og:image:width" content="150" />
          <meta property="og:image:height" content="150" />
        </Helmet>

        <h1>Canadian Music Venue Profiles</h1>
        <div style={{marginBottom:"15px"}}>
          <ul className="nav navbar-nav navbar-right navbar-contact top">

          <li className="navbar-link"><Link to="/fests/british_columbia_fests">Toronto</Link></li>
          <li className="navbar-link"><Link to="/fests/prairies_fests">Vancouver</Link></li>
          <li className="navbar-link"><Link to="/fests/ontario_fests">Calgary</Link></li>
          <li className="navbar-link"><Link to="/fests/quebec_fests">Montreal</Link></li>
          <li className="navbar-link"><Link to="/fests/altantic_fests">Halifax</Link></li>
          <li className="navbar-link"><Link to="/fests/altantic_fests">Ottawa</Link></li>
          <li className="navbar-link"><Link to="/fests/altantic_fests">Hamilton</Link></li>
          </ul>
        </div>

        {this.state && this.state.cities && (
          this.state.cities.map(city => (
            <VenueList title={city+" Venues"} />
          ))
        )}
      </div>
    ):(<></>)}
      </>
    )
  }

  handleArtistSubmit(e){
    e.preventDefault();
    console.log("ARTIST SUBMIT");
    var path = '/festivals/artist?artist='+encodeURIComponent(document.getElementById('artist-fill-festivals').value);
    this.props.navigate(path);
  }


  cityToggle(){
    console.log("CITY");
    var region = document.getElementById('region-selector');
    var artist = document.getElementById('fest-artist-selector');
    if(!artist.classList.contains('hide')){
        artist.classList.toggle('hide');
        //artist.style['pointer-events'] = 'none';
    }
    if(!region.classList.contains('hide')){
      //region.style['pointer-events'] = 'all';
    }
    region.classList.toggle('hide');
  }

  artistToggle(){
    console.log("ARTIST");
    var artist = document.getElementById('fest-artist-selector');
    var region = document.getElementById('region-selector');

    if(!region.classList.contains('hide')){
        region.classList.toggle('hide');
        //region.style['pointer-events'] = 'none';
    }
    if(!artist.classList.contains('hide')){
    //  artist.style['pointer-events'] = 'all';
    }
    artist.classList.toggle('hide');


  }

  constructor(props){
    super(props);
    // if(!this.props.slug) return;

    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;

   var url = "/epi/article/filtered?limit=3";
      fetch(url,{
      method: "get",
      headers: h})
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          // this.setState(result);
          this.setState({"cities":['toronto','vancouver','calgary','montreal','halifax','ottawa','hamilton']});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {

  }
}
export default VenueLayout
