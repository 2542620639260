/* eslint-disable */
import React from "react";
import ContactNav from "./ContactNav";
import "../styles/ArticleStory.css";

// TODO: fix html parsing for body


class ContactPublished extends React.Component {
  render() {
    return (

    <div className="main-container contact-container">
        <ContactNav slug='getpublished' />
        <div className="article-story">
          <h1>Get Published</h1>

          <div className="article-body">

We’re looking for passionate music listeners and experienced writers to contribute to Exclaim! for the following sections:
<ul>
<li>Suggestions of emerging Canadian artists for Exclaim!’s monthly New Faves roundups</li>
<li>Album reviews</li>
<li>Feature articles on scenes and trends relevant to a Canadian audience</li>
</ul>
Please send pitches for any of the above along with a few writing samples to our Features and Reviews Editor Matt Bobkin at <a href="mailto:bobkin@exclaim.ca">bobkin@exclaim.ca</a>. <br/><br/>
If you’re interested in writing about film, please reach out to General Manager Alex Hudson at <a href="mailto:alex@exclaim.ca">alex@exclaim.ca</a>. If you’re a visual artist and/or concert photographer, please send your portfolio to Matt Bobkin at <a href="mailto:bobkin@exclaim.ca">bobkin@exclaim.ca</a>.<br/><br/>


          </div>
        </div>
      </div>

    )
  }
}
export default ContactPublished
