/* eslint-disable */
import React from "react"
import {Link} from "react-router-dom"
import "../styles/ArticleList.css";
import VenueItem from "./VenueItem";

class VenueList extends React.Component {
  // state = this.props.articles;
  state = [];
  render() {
    return (
      <div className="list">
        <h3 className="widget-title">{this.props.title}
          <Link className="button fancy-button widget-arrow"
            to="/"><i className="material-icons">skip_next</i></Link>
        </h3>
        <div className="article-item-list">
          <div className="container">
          {this.props.articles && (
            <>
            {this.props.articles.map(article=>(
              <VenueItem key={article.id} article={article}/>
            ))}
            </>)}
          </div>
        </div>
      </div>
    )
  }


}
export default VenueList
