/* eslint-disable */
import React from "react";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import "../styles/ArticleStory.css";
import { format } from "date-fns";
// import { article } from "../../db/story";

// TODO: fix html parsing for body


class ArticleStory extends React.Component {

  // state = article;
  meta = {};
  render() {
    let description = '';;
    let keys = '';


    return (
      <div class="article-story">
      {this.state?(
        <>


        <h1>{this.state.title}</h1>
        <h2>{this.state.subtitle}</h2>
        <blockquote>{this.state.deck}</blockquote>
        <div class="article-img">
        {this.state.photo?(
          <>
          <img
            src={"https://exclaim.ca" + this.state.photo.photo_file_name }/>
          </>):(<></>)}
        </div>
        <em class="article-byline">Published { this.state.live_at ? format(new Date(this.state.live_at),'MMM d, yyyy') : ''} by {this.state.authors && this.state.authors[0]?(<Link to={"/writers/" + this.state.authors[0].slug }>{this.state.authors[0].firstname + ' ' + this.state.authors[0].lastname}</Link>):(<em></em>)}</em>
        <div class="article-body" dangerouslySetInnerHTML={{ __html: this.state.body }} />

        {this.state.artists?(
        <div class=" article-body">
        {this.state.artists.map(artist=>(
          <Link className="more"
            to={"/artists/"+artist.slug}> More {artist.name} <i class="material-icons">skip_next</i></Link>
        ))}
        </div>
        ):(<></>)}

        {this.state.tags?(
        <div class=" article-body">
        {this.state.tags.map(tag=>(
          <Link className="tag"
            href={['music','film','comedy'].includes(tag.slug)?"/"+tag.slug:"/all/"+tag.slug}>{tag.title} <i class="material-icons">skip_next</i></Link>
        ))}
        </div>
        ):(<></>)}

        </>
        ):(<></>)}
      </div>
    )
  }

  constructor(props){
    super(props)

    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
   var url = "/epi/article/"+props.slug;
    fetch(url,{
      method: "get",
      headers: h})
      .then(res => res.json())
      .then(
        (result) => {
          console.log("ARTICLE");
          console.log(result);
          this.setState(result);
          this.meta = {'title':result.title};

          let doc = new DOMParser().parseFromString(this.state.body, 'text/html');
          this.description = doc.body.textContent.substring(0,240)+"..." || "";;
          this.state.tags.forEach(tag=>{
              this.keys += tag.slug+', ';
          });
          this.state.artists.forEach(artist=>{
              this.keys += artist.slug+', ';
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    // if(this.props.article){
    //   this.setState(this.props.article);
    //   return;
    // }
    // if(!this.props.slug) return;


  }


}
export default ArticleStory
