/* eslint-disable */
import React from "react"
import { Link } from "react-router-dom";
import "../styles/NavBar.css";

class StreamWidget extends React.Component {
  render() {
    return (
      <div style={{padding:"12px",paddingLeft:"0px",width:"100%",height:"200px"}}>
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/j706aSetFbI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <h2 style={{color:"#fff",marginTop:"0px"}}>Featured Stream</h2>
      </div>
    )
  }
}
export default StreamWidget
