/* eslint-disable */
import React from "react";
import {Link} from "react-router-dom";
import "../styles/ContactNav.css";

// TODO: fix html parsing for body


class ContactNav extends React.Component {
  render() {
    return (

      <div style={{marginBottom:"1em"}}>
        <ul className="nav navbar-nav navbar-right navbar-contact top">
        <li className="navbar-link link-label"><strong>OUR:</strong> </li>
        {this.props.slug !== undefined &&
        <li className="navbar-link"><Link to="/contact">Contacts</Link></li>
        }
        {this.props.slug != 'partnership' &&
        <li className="navbar-link"><Link to="/contact/partnership">Partnerships</Link></li>
        }
        {this.props.slug != 'advertising' &&
        <li className="navbar-link"><Link to="/contact/advertising">Advertising</Link></li>
        }
        {this.props.slug != 'distribution' &&
        <li className="navbar-link"><Link to="/contact/distribution">Distribution</Link></li>
        }
        </ul>
        <ul className="nav navbar-nav navbar-right navbar-contact bottom">
        <li className="navbar-link link-label"><strong>GET:</strong> </li>
        {this.props.slug != 'getpublished' &&
        <li className="navbar-link"><Link to="/contact/getpublished">Published</Link></li>
        }
        {this.props.slug != 'getcoverage' &&
        <li className="navbar-link"><Link to="/contact/getcoverage">Coverage</Link></li>
        }
        </ul>
      </div>


    )
  }
}
export default ContactNav
