/* eslint-disable */
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import "../styles/ArticleSolo.css";

//{{class!= undefined ?class:''}}

class ArticleSolo extends React.Component {

  render() {
    return (
      <div className="picks article-solo ">      
        <Link to={this.props.article.canonical_slug}>
          <div className="article-solo-container">
            <div className="article-solo-img">

              <img src={"https://exclaim.ca" + this.props.article.photo.photo_file_name}/>
              <div className="ranked-box">

              </div>
            </div>
            <div className="picks-title">
              <h2>{ this.props.article.title }</h2>
              { this.props.article.subtitle &&
              <h3>{ this.props.article.subtitle }</h3>
              }
            </div>
          </div>
        </Link>
        {this.props.more_path && this.props.more_title &&
          <Link className="more"
            to={this.props.more_path}>{this.props.more_title}<i className="material-icons">skip_next</i></Link>
        }
      </div>
    )
  }
}
export default ArticleSolo
