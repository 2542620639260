/* eslint-disable */
import React from "react";
import {Helmet} from "react-helmet";
import ArticleList from "../partials/ArticleList";
// import { articles as articles_latest } from "../../db/latest";



class ListLayout extends React.Component {
  state = {"title":"Loading Articles"};
  render() {
    const title = this.state.title.toLowerCase().replace(/\b[a-z]/g, function(letter) {
      return letter.toUpperCase();
    });
    const keys = this.state.title.replaceAll(" ",", ");
    return (
      <>
      {this.state && this.state.articles && this.state.articles.content && this.state.articles.content.length > 0 ?(
      <div className="main-container filtered-container">
      {!this.props.widget&&(
        <Helmet>
          <title>{title} on Exclaim!</title>
          <meta name="twitter:title" content={title+" on Exclaim!"} />
          <meta property="og:title" content={title+" on Exclaim!"} />
          <link rel="canonical" href="https://exclaim.ca/festivals" />
          <meta property="og:url" content="https://exclaim.ca/festivals" />
          <meta name="keywords" content={"articles, coverage, media, "+keys} />

          <meta name="description" content={this.state.title+" coverage by Exclaim!"} />
          <meta property="og:description" content={this.state.title+" coverage by Exclaim!"} />
          <meta name="twitter:description" content={this.state.title+" coverage by Exclaim!"} />

          <meta property="og:image" content="http://exclaim.ca/assets/images/square_logo.png" />
          <meta property="og:image:secure_url" content="https://exclaim.ca/assets/images/square_logo.png" />
          <meta name="twitter:image" content="https://exclaim.ca/assets/images/square_logo.png" />
          <meta property="og:image:width" content="150" />
          <meta property="og:image:height" content="150" />
        </Helmet>
        )}
        <ArticleList articles={this.state.articles.content} page={this.state.page} title={this.state.title} more_path={!this.props.section?'':'/'+this.props.section+(this.props.filter?'/'+this.props.filter:'')}/>
      </div>
    ):(<></>)}
      </>
    )
  }

  constructor(props){
    super(props);
    // if(!this.props.slug) return;

    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
   if(props.title){
     var title = props.title;
   }else{
     if(props.section){
       var title = props.section + ' ' + props.filter;
     }else{
       var title = props.filter;
     } 
   }
   var url = "/epi/article/filtered";
   if(props.section && props.section != 'all'){
     url = url + '/'+props.section;
   }
   if(props.filter && props.filter != 'latest'){
     url = url + '?filter='+props.filter+'&';
   }else{
     url = url + '?';
   }
   if(props.limit){
      url = url + 'limit='+props.limit;
   }else{
      url = url + 'limit=30';
   }
   var page = 1;
   if(props.page){
      url = url + '&page='+props.page;
      page = props.page;
   }else{
      url = url + '&page=1';
   }


    fetch(url,{
      method: "get",
      headers: h})
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({"articles":result.articles,"title":title, 'page':page});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {

  }
}



export default ListLayout
