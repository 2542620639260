/* eslint-disable */
import React from "react";
import {Helmet} from "react-helmet";
import Picks from "../partials/Picks";
import ArticleQuad from "../partials/ArticleQuad";
import { fixCards} from "../scripts/core.js";
import { articles as articles_trending } from "../../db/trending";
import { articles as articles_latest } from "../../db/latest";
import { articles as articles_features } from "../../db/features";
import { articles as articles_reviews } from "../../db/reviews";


class SearchLayout extends React.Component {
  //state = {"articles":{"content":[]},"albums":{"content":[]}}; // {"trending":{"title":"Trending","articles":[]}, "latest":{"title":"Latest","articles":[]},"features":{"title":"Features","articles":[]},"reviews":{"title":"Reviews","articles":[]}};
  render() {
    return (
      <>
      {this.state?(
        <div>
          <Helmet>
            <title>Search | Results for '{this.props.query}' on Exclaim!</title>
            <meta name="twitter:title" content={"Search | Results for '"+this.props.query+"' on Exclaim!"} />
            <meta property="og:title" content={"Search | Results for '"+this.props.query+"' on Exclaim!"} />
            <link rel="canonical" href="https://exclaim.ca/trending21" />
            <meta property="og:url" content="https://exclaim.ca/trending21" />
            <meta name="keywords" content="today, trending, popular, music, film, comedy" />

            <meta name="description" content="Search the archives... there's a lot to search." />
            <meta property="og:description" content="Search the archives... there's a lot to search." />
            <meta name="twitter:description" content="Search the archives... there's a lot to search." />

            <meta property="og:image" content="http://exclaim.ca/assets/images/square_logo.jpg" />
            <meta property="og:image:secure_url" content="https://exclaim.ca/assets/images/square_logo.jpg" />
            <meta name="twitter:image" content="https://exclaim.ca/assets/images/square_logo.jpg" />
            <meta property="og:image:width" content="150" />
            <meta property="og:image:height" content="150" />
          </Helmet>
          {this.state.articles && this.state.articles.content.length > 0 &&
          <ArticleQuad title="Article Matches" more_path={"/trending21"} articles={this.state.articles} />
          }
          {this.state.albums && this.state.albums.content.length > 0 &&
          <ArticleQuad title="Album Matches" more_path={"/all/latest"} articles={this.state.albums} />
          }
        </div>
      ):(<></>)}
      </>
    )
  }

  constructor(props){
    super(props);
    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
    });
    var url = "/epi/search/"+props.query;
     fetch(url,{
       method: "get",
       headers: h})
       .then(res => res.json())
       .then(
         (result) => {
           console.log(result);
           this.setState(result);
         },
         // Note: it's important to handle errors here
         // instead of a catch() block so that we don't swallow
         // exceptions from actual bugs in components.
         (error) => {
           this.setState({
             isLoaded: true,
             error
           });
         }
       )
  }

  componentDidMount() {

  }

  componentDidUpdate(){
    console.log("Fixing cards");
    fixCards();
  }
}
export default SearchLayout
