/* eslint-disable */
import React from "react"
import { Link } from "react-router-dom";
import "../styles/NavBar.css";
import StreamWidget from "./StreamWidget"
class NavBar extends React.Component {

  constructor(props){
    super(props);
  }

  searchToggle(e){
    e.preventDefault();
    window.location.href = "/search/"+document.getElementById('query').getVal();
  }

  handleQueryKey(){

  }

  render() {
    return (
      <div className="drawer" id="drawer">
        <ul className="drawer-list">
          <li className="brand-item"><Link to="/" className="brand"></Link></li>
          <li><Link to="/trending21">Trending</Link><i className="material-icons">local_fire_department</i></li>
          <li><Link to="/all/latest">Latest</Link><i className="material-icons">fiber_new</i></li>
          <li><Link to="/concerts">Concerts</Link><i className="material-icons">local_activity</i></li>
          <li><Link to="/festivals">Festivals</Link><i className="material-icons">festival</i></li>
          <li><Link to="/all/new-faves">New Faves</Link><i className="material-icons">thumb_up</i></li>
          <li><Link to="/music">Music</Link><i className="material-icons">headphones</i><br/>
            <ul className="drawer-sublist">
              <li><Link to="/music/news">News</Link></li>
              <li><Link to="/music/reviews">Reviews</Link></li>
              <li><Link to="/music/features">Features</Link></li>
              <li><Link to="/music/streams">Streams</Link></li>
            </ul>
          </li>
          <li><Link to="/film">Film</Link><i className="material-icons">movie</i><br/>
            <ul className="drawer-sublist">
              <li><Link to="/film/news">News</Link></li>
              <li><Link to="/film/reviews">Reviews</Link></li>
              <li><Link to="/film/features">Features</Link></li>
            </ul>
          </li>
          <li><Link to="/comedy">Comedy</Link><i className="material-icons">sentiment_very_satisfied</i><br/>
            <ul className="drawer-sublist">
              <li><Link to="/comedy/news">News</Link></li>
              <li><Link to="/comedy/reviews">Reviews</Link></li>
              <li><Link to="/comedy/features">Features</Link></li>
            </ul>
          </li>
          <li><Link to="/contests">Contests</Link><i className="material-icons">redeem</i></li>
          <li><Link to="/guides">Guides</Link><i className="material-icons">connect_without_contact</i></li>
          <li><Link to="/contact">Contact</Link><i className="material-icons">connect_without_contact</i></li>
          <li>
            <a onClick={this.searchToggle}>Search</a><i className="material-icons " onClick={this.searchToggle}>search</i>
            <div className="form-group" id="query" style={{paddingRight:"1em",marginTop:"1em"}}>
              <input name="query" className="form-control singlevalue" type="text" onChange={e => this.setEventForm({ ...eventForm, type: e.target.value })}/>
            </div>
          </li>
          <li className="starred"><Link to="/thepoll">Reader's Poll '21</Link><i className="material-icons">poll</i></li>
          <li><StreamWidget /></li>
        </ul>

      </div>
    )
  }
}
export default NavBar
