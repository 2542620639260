/* eslint-disable */
import React from "react"
import {Link} from "react-router-dom"
import ConcertItem from "./ConcertItem";

class ConcertList extends React.Component {
  render() {
    return (
      <div className="list">
        <h3 className="widget-title">Canadian Concerts
        </h3>
        <div className="article-item-list">
          <div className="container">
            {this.props.events.events.map(date=>(
              <>
              <h5 style={{marginTop:"0.25em",backgroundColor:"#000",color:"#fff",padding:"0.5em"}}>{date.date} <span style={{float:"right"}}>Monday</span></h5>
              {date.events.map(event=>(
              <ConcertItem event={event}/>
              ))}
              </>
            ))}
          </div>
        </div>

        <Link className="more"
          to="/music/concerts/page/2"> More Concerts<i className="material-icons">skip_next</i></Link>
      </div>
    )
  }
}
export default ConcertList
