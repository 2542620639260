/* eslint-disable */
import React from "react";
import ContactNav from "./ContactNav";
import "../styles/ArticleStory.css";

// TODO: fix html parsing for body


class ContactPartnership extends React.Component {
  render() {
    return (

    <div className="main-container filtered-container">
        <ContactNav slug='partnership' />
        <div className="article-story">
          <h1>Partnerships</h1>
          <div className="article-author"></div>
          <div className="article-published"></div>
          <figure>
          <div className="article-img"><img src="https://exclaim.ca/AboutUsAssets/Partnerships.jpg" alt="Partnerships" /><figcaption></figcaption></div>
          </figure>
          <div className="article-body">
            <b>Invite us to your party! Here at Exclaim! we love getting involved with events all over Canada...</b><br/><br/>
            We're open to partnering with all kinds of events, from concert tours and music festivals to film festivals and local art and music events. Past and current partnerships include Pop Montreal, CMJ, Halifax Pop Explosion and Wavelength among many others.<br/><br/>
            Please get in touch if:<br/>
            - You are organizing an event in your local music community and want Exclaim! to be involved<br/>
            - You work with an organization and want to discuss sponsorship from Exclaim!<br/>
            - Your band is touring around the country and is interested in sponsorship from Exclaim! <br/><br/>
            Be sure to include all of the event details, and of course, the earlier you get in touch with us, the better. Three months = good. Two weeks = bad.
            <br/><br/>
            For more information on partnerships and sponsorships, contact Ian Danzig at (416) 535-9735 ext 27 or write to <a href="mailto:ian@exclaim.ca">ian@exclaim.ca</a>.
          </div>
        </div>
      </div>

    )
  }
}
export default ContactPartnership
