export function fixCards (skip = []){
    // do some stuff

  var cards = document.getElementsByClassName("article-card-img");
  var firstCol = true;
  var fHeight;
  var fImg;
  var fCard;
  var unit = 0;
  console.log(cards);
  for( let card of cards){
    unit++;
    if(skip.includes(unit)) continue;
    console.log("doin a card");
    var img = card.getElementsByTagName('img')[0];
    if(firstCol){
      fImg = img;
      fCard = card;
      fHeight = img.clientHeight;
      console.log("FIRST HEIGHT:" +fHeight);
    }else{
      var sHeight = img.clientHeight;
      console.log("SECOND HEIGHT:" +sHeight);
      if(fHeight > sHeight){
        card.style.height = (fCard.clientHeight)+'px';
        img.style.height = fHeight+'px';
        img.style.maxHeight = fHeight+'px';
        img.style.objectFit =  "cover";
        img.style.objectPosition =  "center";
        console.log("first card bigger");
      }else if(sHeight > fHeight){
        fCard.style.height = card.clientHeight+'px';
        fImg.style.height = sHeight+'px';
        fImg.style.maxHeight = sHeight+'px';
        fImg.style.objectFit =  "cover";
        fImg.style.objectPosition =  "center";
        console.log("second card bigger");
      }
    }
    firstCol = !firstCol;
  }
};
