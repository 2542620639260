/* eslint-disable */
import React from "react";
import {Helmet} from "react-helmet";
import ArticleCard from "../partials/ArticleCard";
import ArticleSolo from "../partials/ArticleSolo";
import TrendingList from "../partials/TrendingList";
import { articles as articles_trending } from "../../db/trending";
import { fixCards} from "../scripts/core.js";
import "../styles/TrendingLayout.css";



class TrendingLayout extends React.Component {
  // state = {"articles":[]};
  render() {
    return (
      <>
      {this.state && this.state.articles?(
      <div className="main-container trending-container">
        <Helmet>
          <title>Trending 21 | Today's Top Music, Film & Comedy Stories on Exclaim!</title>
          <meta name="twitter:title" content="Trending 21 | Today's Top Music, Film & Comedy Stories on Exclaim!" />
          <meta property="og:title" content="Trending 21 | Today's Top Music, Film & Comedy Stories on Exclaim!" />
          <link rel="canonical" href="https://exclaim.ca/trending21" />
          <meta property="og:url" content="https://exclaim.ca/trending21" />
          <meta name="keywords" content="today, trending, popular, music, film, comedy" />

          <meta name="description" content="The top 21 trending stories of the day, as determined by you." />
          <meta property="og:description" content="The top 21 trending stories of the day, as determined by you." />
          <meta name="twitter:description" content="The top 21 trending stories of the day, as determined by you." />

          <meta property="og:image" content="http://exclaim.ca/assets/images/square_logo.jpg" />
          <meta property="og:image:secure_url" content="https://exclaim.ca/assets/images/square_logo.jpg" />
          <meta name="twitter:image" content="https://exclaim.ca/assets/images/square_logo.jpg" />
          <meta property="og:image:width" content="150" />
          <meta property="og:image:height" content="150" />
        </Helmet>
        <div className="quad">
          <h1 className="widget-title">
            Trending 21
            <a
              className="button fancy-button"><i className="material-icons">local_fire_department</i></a>
          </h1>

          {this.state.articles.length > 0 &&
            <TrendingList articles={this.state.articles}/>
          }
        </div>
      </div>
      ):(<></>)}
      </>
    )
  }

  constructor(props){
    super(props);
    // if(!this.props.slug) return;

    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
   // var title = this.props.section + ' ' + this.props.filter;
   var url = "/epi/trending21";
    fetch(url,{
      method: "get",
      headers: h})
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({"articles":result});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {

  }


  componentDidUpdate(){
    console.log("Fixing cards");
    fixCards([1,6,11]);
  }
}
export default TrendingLayout
