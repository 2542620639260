/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import "../styles/ConcertItem.css";
//| date:'HH:mm'
class ConcertItem extends React.Component {
  render() {
    return (
      <Link to={"/music/concert/"+this.props.event.slug}>
        <div className="concert-item">
          <div className="concert-item-title">
            <h4 className="more concert-name">{this.props.event.name}<i className="material-icons">skip_next</i></h4>
            <h5>{ false ? format(new Date(this.props.event.doors_open_at),'MMM d, yyyy') : this.props.event.doors_open_at } @ { this.props.event.venue.name}<br/>
            {this.props.event.venue.city}
            </h5>

          </div>
        </div>
      </Link>
    )
  }
}
export default ConcertItem
