/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

import { article } from "../../db/story";
import "../styles/ArticleItem.css";
// TODO: fix html parsing for body


class FestItem extends React.Component {

  state = article;

  render() {
    return (
      <Link to={"/fests/fest/"+this.props.article.slug}>
        <div className="article-item">
        <div
          className="article-item-img">
          <img
            src={"https://exclaim.ca" + this.props.article.photo.photo_file_name}
          />
        </div>
          <div className="article-item-title">
            <h4>{this.props.article.title}</h4>
            <h5>{this.props.article.subtitle}</h5>
            <blockquote>{this.props.article.deck}</blockquote>
          </div>
        </div>
      </Link>
    )
  }
}
export default FestItem
