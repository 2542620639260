/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import ContactNav from "./ContactNav";
import "../styles/ArticleStory.css";

// TODO: fix html parsing for body


class ContactAdvertising extends React.Component {
  render() {
    return (

    <div className="main-container contact-container">
        <ContactNav slug='advertising' />
        <div className="article-story">
          <h1>Advertising</h1>

          <div className="article-body">



          <h2>Exclaim! Media Kit</h2>
          <Link to="/contact/advertising/printratecard">Exclaim! Magazine Print Rate Card</Link>
          <br/>
          <Link to="/contact/advertising/distribution">Exclaim! Magazine Distribution</Link>
          <br/>
          <Link to="/contact/advertising/readership">Exclaim! Magazine Readership</Link>
          <br/>
          <Link to="/contact/advertising/calendar">Exclaim! Magazine Calendar</Link>
          <br/>
          <Link to="/contact/advertising/printartworkspecification">Exclaim! Magazine Print Artwork Specifications </Link>
          <br/>
          <br/>
          <Link to="/contact/advertising/onlineinfosheet">Exclaim! Media Online Marketing Information Sheet</Link>
          <br/>
          <Link to="/contact/advertising/mediacontentmarketing">Exclaim! Media Content Marketing</Link>
          <br/>
          <Link to="/contact/advertising/content">Exclaim.ca Content</Link>
          <br/>
          <Link to="/contact/advertising/newsletter">Exclaim.ca Email Newsletter</Link>
          <br/><br/>
          <Link to="/contact/advertising/summer_screens_preview">Exclaim! Summer Screens Preview</Link>
          <br/>
          <Link to="/contact/advertising/exclaim_summer_festival_guide">Exclaim! Summer Music Festival Guide</Link>
          <br/>
          <Link to="/contact/advertising/hangout_handbook">Exclaim! Fall Hangout Handbook</Link>
          <br/>
          <Link to="/contact/advertising/hangout_handbook_campus_distribution">Exclaim! Hangout Handbook Campus Distribution</Link>
          <br/>
          <Link to="/contact/advertising/gift_guide">Exclaim! Holiday Gift Guide</Link>
          <br/>
          <Link to="/contact/advertising/readers_poll">Exclaim! Year End Readers Poll</Link>
          <br/><br/>
          Exclaim! connects with Canada's young, music-loving audience through a wide variety of platforms:<br/>
          - Exclaim! Magazine (National monthly with 100,000+ CCAB audited circulation, Canada's music and youth culture publication since March 1992)<br/>
          - Exclaim.ca website<br/>
          - National Tours<br/>
          - Special Regional Events and Concerts<br/>
          - Product Launches<br/>
          - National and Regional Product Sampling<br/>
          - Contests and Cross Promotions<br/>
          - Pre-Printed Regionally Targeted Magazine Inserts<br/>
          - Customized Programs<br/>
          To learn more about the above opportunities, please contact Ian Danzig at <Link to="mailto:ian@exclaim.ca">ian@exclaim.ca</Link> or 416 535-9735 x27<br/><br/>


          </div>
        </div>
      </div>

    )
  }
}
export default ContactAdvertising
