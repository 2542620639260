/* eslint-disable */
import React from "react"
import {Link} from "react-router-dom"
import ContestItem from "./ContestItem";
import "../styles/ArticleList.css";
class ContestList extends React.Component {
  render() {
    return (
      <div className="list">
        <h3 className="widget-title">{this.props.title}
          <Link
            className="button fancy-button widget-arrow"
            to={"/" + this.props.more_path}><i className="material-icons">skip_next</i></Link>
        </h3>
        <div className="article-item-list">
          <div className="container">

            {this.props.contests.map(contest=>(
              <ContestItem contest={contest}/>
            ))}



          </div>
        </div>

        <Link className="more"
          to={ this.props.more_path}>{ 'More ' + this.props.title }<i className="material-icons">skip_next</i></Link>
      </div>
    )
  }
}
export default ContestList
