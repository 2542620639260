/* eslint-disable */
import React from "react";
import ContactNav from "./ContactNav";
import "../styles/ArticleStory.css";

// TODO: fix html parsing for body


class ContactDistribution extends React.Component {
  render() {
    return (

    <div className="main-container contact-container">
        <ContactNav slug='distribution'/>
        <div className="article-story">
          <h1>Distribution</h1>

          <div className="article-body">

          Exclaim! is free to you and your clients. Because we take pride in making it available for anyone to read anywhere in the country, it doesn't matter to us if you're a café in Sudbury or a nightclub in Nunavut. <br/><br/>
          The minimum quantity of copies to distribute is ten in Toronto and surrounding areas, Montreal, Vancouver, Hamilton, Halifax, London, Winnipeg, Calgary, Edmonton, Kitchener, Waterloo, Guelph, Ottawa, Quebec City and St. Catharines. Due to shipping costs, the minimum quantity in all other areas is 25. <br/><br/>
          If you are interested in distributing Exclaim!, download the <a href="/AboutUsAssets/outlet_registration.pdf" target="_blank">Outlet Registration Form PDF</a>. Please sign, date, fill in the shipping information and then fax (416.535.0566), or scan and email the form to <a href="mailto:distribution@exclaim.ca">distribution@exclaim.ca</a>. We will not be able to begin shipping you issues without a signed and dated form. <br/><br/>
          For any complaints, concerns or to modify a quantity, please contact our Distribution Manager at <a href="mailto:distribution@exclaim.ca">distribution@exclaim.ca</a>.<br/><br/>
          <h2>Distribution Statistics!</h2>
          Exclaim's circulation is audited by the <a href="http://www.bpaww.com" target="_blank">CCAB (a division of BPA worldwide)</a>, the global industry resource for verified audience data and media knowledge. To find out more download a copy of our latest <a href="/AboutUsAssets/distribution.pdf" target="_blank">Distribution PDF</a>.<br/><br/>Exclaim! magazine is available coast to coast in Canada at all key hangouts in major urban markets. To see further breakdown of our demographics take a look at our <a href="/AboutUsAssets/readership.pdf" target="_blank">Readership PDF</a>.

          </div>
        </div>
      </div>

    )
  }
}
export default ContactDistribution
