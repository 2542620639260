/* eslint-disable */
import React from "react"
import {Link} from "react-router-dom"
import "../styles/ArticleQuad.css";
import ArticleCard from "./ArticleCard";


class ArticleQuad extends React.Component {
  render() {
    return (
      <div className="quad ">
          <h3 className="widget-title">{ this.props.title }
            <Link
              className="button fancy-button widget-arrow"
              to={ this.props.more_path}><i className="material-icons">skip_next</i></Link>
          </h3>
          <div className="quadrant">
            <div className="container">
              {this.props.articles.content && this.props.articles.content.length > 0 &&
                this.props.articles.content.map(article=>(
                <ArticleCard key={article.id} article={article}/>
              ))}

            </div>
          </div>

          <Link  className="more"
            to={ this.props.more_path}>More {this.props.title}<i className="material-icons">skip_next</i></Link>
        </div>
    )
  }
}
export default ArticleQuad
