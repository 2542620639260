/* eslint-disable */
import React from "react";
import ContactNav from "./ContactNav";
import "../styles/ArticleStory.css";

// TODO: fix html parsing for body


class ContactCoverage extends React.Component {
  render() {
    return (

    <div className="main-container contact-container">
        <ContactNav slug='getcoverage' />
        <div className="article-story">
          <h1>Get Coverage</h1>

          <div className="article-body">



          <h2>Reviews</h2>
          Music submissions for review can be submitted digitally to our Features Editor Matt Bobkin (<a href="mailto:bobkin@exclaim.ca">bobkin@exclaim.ca</a>). Please include any available notes or press releases for context.
          <h2>Premieres</h2>
          If you're interested in setting up an exclusive album stream or premiering a new song/video at Exclaim!, please contact <a href="mailto:tips@exclaim.ca">tips@exclaim.ca</a>. However, due to overwhelming demand, Exclaim! cannot guarantee any premiere placement. If we’re interested, we will get back to you.<br/><br/>
          Include an artist bio, up-to-date press release, links to Facebook and Twitter profiles, and any additional relevant information about the project. For track or album streams, please upload the content to a private SoundCloud player and send us the embed code. Please also send us the finished art. For videos, we prefer 'unlisted' YouTube links.<br/><br/>
          For all premieres, we ask that you ensure that the content you provide is exclusive to Exclaim! and has not yet been shared elsewhere online. Please hold off on contacting us until you have a complete version of the track and a date in mind for the premiere.



          </div>
        </div>
      </div>

    )
  }
}
export default ContactCoverage
