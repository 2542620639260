/* eslint-disable */
import React from "react";
import "../styles/ArticleStory.css";
import ContactNav from "./ContactNav";

// TODO: fix html parsing for body


class ContactHome extends React.Component {
  render() {
    return (
<div className="main-container contact-container">
      <ContactNav/>
      <div className="article-story">
        <h1>Contact Exclaim! Media</h1>

        <div className="article-body">

          <strong>Exclaim! Media Inc.</strong> (<a href="/">http://exclaim.ca</a>) is Canada's foremost source of news and information on the best in emerging new music. Established in 1991, Exclaim! reaches a national audience through print, digital and live event platforms.
          <br/><br/>
          In print, Exclaim! Magazine is Canada's premier national music culture publication with a monthly circulation of over 100,000 copies distributed through more than 4,000 outlets across Canada. Exclaim.ca is the cornerstone of the Exclaim! digital presence, which includes web, mobile and social media platforms, focusing on streaming music, news, reviews, interviews and original video content. The Exclaim! events platform presents national concert tours and regional showcases for Canada's most exceptional up and coming performers.
          <br/><br/>
          <span className="hint">We acknowledge the assistance of the OMDC Magazine Fund, an initiative of the Ontario Media Development Corporations</span><br/><br/>

          <h3>Mailing Address</h3>
          Exclaim!<br/>
          849A Bloor St. W.<br/>
          Toronto, Ontario M6G 1M3<br/>
          Phone: 416.535.9735<br/>
          Fax: 416.535.0566<br/>
        </div>
      </div>
      <div className="stream">
      <div className="stream-title">
      <h3>Exclaim! Contacts</h3>
      </div>
      <ul className="streamSingle">
      <li className="streamSingle-item">
      <a href="mailto:editorial@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Editorial</div>
      <div className="streamSingle-item-excerpt">editorial@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:ads@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Advertising</div>
      <div className="streamSingle-item-excerpt">ads@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:distribution@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Distribution</div>
      <div className="streamSingle-item-excerpt">distribution@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:ads@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Ad Production</div>
      <div className="streamSingle-item-excerpt">ads@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:tips@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">News Tips</div>
      <div className="streamSingle-item-excerpt">tips@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:listings@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Concert &amp; Festival Listings</div>
      <div className="streamSingle-item-excerpt">listings@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:exclaim@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">General</div>
      <div className="streamSingle-item-excerpt">exclaim@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="stream-title"><h3>Publisher</h3></li>
      <li className="streamSingle-item">
      <a href="mailto:ian@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Ian Danzig</div>
      <div className="streamSingle-item-excerpt">ian@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="stream-title"><h3>Director of Operations</h3></li>
      <li className="streamSingle-item">
      <a href="mailto:atsuko@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Atsuko Kobasigawa</div>
      <div className="streamSingle-item-excerpt">atsuko@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="stream-title"><h3>Marketing Solutions</h3></li>
      <li className="streamSingle-item">
      <a href="mailto:john@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">John Price</div>
      <div className="streamSingle-item-excerpt">john@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:lisa@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Lisa Vanderwyk</div>
      <div className="streamSingle-item-excerpt">lisa@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:pwallace@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Paula Wallace</div>
      <div className="streamSingle-item-excerpt">pwallace@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:arod@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Alex Rodobolski</div>
      <div className="streamSingle-item-excerpt">arod@exclaim.ca</div>
      </div>
      </a>
      </li>

      <li className="stream-title"><h3>Associate Editor</h3></li>
      <li className="streamSingle-item">
      <a href="mailto:brock@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title"><span className="hint">News, Music and Video Streaming</span><br/>Brock Thiessen</div>
      <div className="streamSingle-item-excerpt">brock@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="stream-title"><h3>General Manager</h3></li>
      <li className="streamSingle-item">
      <a href="mailto:alex@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Alex Hudson</div>
      <div className="streamSingle-item-excerpt">alex@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="stream-title"><h3>Features Editor</h3></li>
      <li className="streamSingle-item">
      <a href="mailto:bobkin@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title"><span className="hint">Album and Concert Reviews</span><br/>Matt Bobkin</div>
      <div className="streamSingle-item-excerpt">bobkin@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="stream-title"><h3>Staff Writer</h3></li>
      <li className="streamSingle-item">
      <a href="mailto:calum@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Calum Slingerland</div>
      <div className="streamSingle-item-excerpt">calum@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="streamSingle-item">
      <a href="mailto:allie@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Allie Gregory</div>
      <div className="streamSingle-item-excerpt">allie@exclaim.ca</div>
      </div>
      </a>
      </li>
      <li className="stream-title"><h3>Guy Who Breaks Things</h3></li>
      <li className="streamSingle-item">
      <a href="mailto:brandon@exclaim.ca">
      <div className="streamSingle-item-content">
      <div className="streamSingle-item-title">Brandon Thorn</div>
      <div className="streamSingle-item-excerpt">brandon@exclaim.ca</div>
      </div>
      </a>
      </li>
      </ul>
      </div>
</div>
    )
  }
}
export default ContactHome
