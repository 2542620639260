/* eslint-disable */
import React from "react";
import { Link} from "react-router-dom";
//| date:'HH:mm'
class ContestItem extends React.Component {
  render() {
    return (
      <Link to={"/contests/article/"+this.props.contest.slug}>
        <div className="article-item">
          <div className="article-item-img">
            <img
              src={"https://exclaim.ca" + this.props.contest.thumbnail}
            />
          </div>
          <div className="article-item-title">
            <h4>{this.props.contest.title}</h4>
          </div>
        </div>
      </Link>
    )
  }
}
export default ContestItem
