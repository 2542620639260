/* eslint-disable */
import React from "react"
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
// import { contests } from "../../db/contests";
import { format } from "date-fns";
import "../styles/ArticleStory.css";
import "../styles/ContestEntry.css";
//| date:'MMM d YYYY'
//h3border-bottom:5px solid #000;line-height:1.5em;position:relative;

class ContestEntry extends React.Component {
  // state = {contest:contests[0],form:{}};
  render() {

    let description = '';
    return (
      <>
      {this.state?(
      <div>
        <Helmet>
          <title>WORDS | Exclaim!</title>
          <meta name="twitter:title" content={this.state.contest.title+" "+this.state.contest.subtitle+" | Exclaim!"} />
          <meta property="og:title" content={this.state.contest.title+" "+this.state.contest.subtitle+" | Exclaim!"} />
          <link rel="canonical" href={"https://exclaim.ca/contests/"+this.state.contest.slug} />
          <meta property="og:url" content={"https://exclaim.ca/contests/"+this.state.contest.canonical_url} />
          <meta name="keywords" content={"contest "+this.state.contest.title+" "+this.state.contest.subtitle} />

          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />

          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <div className="article-story">
          <h1>{this.state.contest.title}</h1>
          <h2>{this.state.contest.subtitle}</h2>
          <blockquote>{this.state.contest.deck}</blockquote>
          <div className="article-img">
            <img
              src={"https://exclaim.ca" + this.state.contest.image}

            />
          </div>
          <em className="article-byline">Published: { format(new Date(this.state.contest.live_at),'MMM d, yyyy')}</em>
          <div className=" article-body"  dangerouslySetInnerHTML={{ __html: this.state.contest.description }} />

        </div>

        <div className="main-container">
          <h3  className="widget-title">Enter to win!
            <Link className="button fancy-button widget-arrow" to="/"><i className="material-icons">celebration</i></Link>
          </h3>
          <form id="contest-form"  onSubmit={this.handleContestSubmit} role="form" name="" method="POST" action="/contest/enter" autoComplete="off">

            <div className="form-group" id="name"><label>Name:</label>
              <input name="name" className="form-control singlevalue" type="text" />
            </div>
            <div className="form-group" id="email"><label>Email:</label>
              <input name="email" className="form-control singlevalue" type="text" />
            </div>
            <div className="form-group" id="address"><label>Address:</label>
              <input name="address" className="form-control singlevalue" type="text" />
            </div>
            <div className="form-group" id="city"><label>City:</label>
              <input name="city" className="form-control singlevalue" type="text" />
            </div>
            <div className="form-group" id="province"><label>Province:</label>
              <select name="province" className="form-control"><option value="">-- Select One --</option>
                <option value="Alberta">Alberta</option>
                <option value="British Columbia">British Columbia</option>
                <option value="Manitoba">Manitoba</option>
                <option value="New Brunswick">New Brunswick</option>
                <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                <option value="Northwest Territories">Northwest Territories</option>
                <option value="Nova Scotia">Nova Scotia</option>
                <option value="Nunavut">Nunavut</option>
                <option value="Ontario">Ontario</option>
                <option value="Prince Edward Island">Prince Edward Island</option>
                <option value="Québec">Québec</option>
                <option value="Saskatchewan">Saskatchewan</option>
                <option value="Yukon">Yukon</option>
              </select>
            </div>
            <div className="form-group" id="country">
              <input name="country" className="pooh" autoComplete="off" type="text" />
            </div>
            <div className="form-group" id="postal_code"><label>Postal Code:</label>
              <input name="postal_code" className="form-control singlevalue" type="text" />
            </div>
            <div className="form-group" id="phone_number"><label>Phone Number:</label>
              <input name="phone_number" className="form-control singlevalue" type="text" />
            </div>
            <div className="form-group" id="age"><label>Age:</label>
              <input name="age" className="form-control singlevalue" type="text" />
            </div>
            <div className="form-group" id="contest_id">
              <input name="contest_id" value="1699" type="hidden" />
            </div>
            {this.state.contest.contest_questions && this.state.contest.contest_questions.length > 0 && (
              <>
              {this.state.contest.contest_questions.map(question => (
                <div className="form-group" id={question.id}><label>{question.question} :</label>
                  <input name={"contest_questions["+question.id+"]"} className="form-control singlevalue" type="text" />
                </div>
              ))}
              </>
            )}
            <div className="form-group" id="rulesbox"><label>Rules And Regulations:</label>
              <div className="multiinput">
                <iframe
                  src={"https://exclaim.ca/legalese/"+this.props.slug}
                  scrolling="yes"></iframe>
                <br/>
                <div className="rules-agree">
                  <div>
                    <input name="rules" value="1" className="form-control singlevalue checkbox" type="checkbox" />
                  </div>
                  <div>
                    <label>I Agree To The Rules And Regulations Above:</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group" id="optin">
              <h3 className="widget-title">Stay Up To Date</h3>
              <div className="multiinput checkbox">
                <div>
                  <input name="home_delivery" value="1" className="form-control singlevalue checkbox" type="checkbox" />
                </div>
                <div><label>I Want Free Home Delivery of Exclaim! Magazine:</label></div>
              </div>
            </div>
            <div className="form-group" id="optin">
              <div className="multiinput checkbox">
                <div>
                  <input name="optin" value="1" className="form-control singlevalue checkbox" type="checkbox" />
                </div>
                <div>
                  <label>I Want To Be The First To Learn About New Contests From <Link to="https://exclaim.ca">Exclaim.ca!</Link>:</label>
                </div>


              </div>
            </div>
            <input type="hidden" name="_token" value="" />
            <div id="message" className="form-message"></div>
            <a id="enter" className="more" onClick={this.handleContestSubmit}>Enter<i className="material-icons">verified</i></a>
          </form>
        </div>
        </div>
      ):(<></>)}
      </>
    )
  }

  handleFieldChange(e){
    // var name = e.target.getAttribute('name');
    // var val = e.target.value;
    // this.setState({contest:result,form:{}});
    this.setState({ [event.target.id]: event.target.value });
  }


  handleContestSubmit(e) {
    e.preventDefault();
    // var formData = document.getElementById('contest-form').elements;
    document.getElementById('message').innerHTML = "";
    const message = document.getElementById('message');
    const formData = new FormData(document.getElementById('contest-form'));
    const data = Array.from(formData.entries()).reduce((memo, pair) => ({
      ...memo,
      [pair[0]]: pair[1],
    }), {});
    const required = ['name','email','address','city','province','postal_code'];
    var pass = true;
    required.forEach(el=>{if(!data[el]){pass = false;}});

    if(data['country'] != ''){
      pass = false;
    }
    if(!/^\S+@\S+\.\S+$/.test(data['email'])){
      message.innerHTML += "Invalid email provided<br/>";
      pass = false;
    }
    if(!data['rules']){
      pass = false;
    }
    if(!pass){
      console.log("Missing required field");
      message.innerHTML += "Missing required field(s)";
      if(!message.classList.contains('error')){
        message.classList.toggle('error');
      }
      return;
    }else{
      message.innerHTML += "Successfully entered!";
      message.classList.toggle('success');
    }

    //var formData = document.getElementById('contest-form').elements;
    console.log(JSON.stringify(data));
    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
   // var url = "/epi/contest/submit/"+this.props.slug;
   //  fetch(url,{
   //    method: "post",
   //    headers: h,
   //    body: JSON.stringify(formData)})
   //    .then(res => res.json())
   //    .then(
   //      (result) => {
   //        //this.setState(result);
   //
   //        console.log(result);
   //      },
   //      // Note: it's important to handle errors here
   //      // instead of a catch() block so that we don't swallow
   //      // exceptions from actual bugs in components.
   //      (error) => {
   //        this.setState({
   //          isLoaded: true,
   //          error
   //        });
   //      }
   //    )
  }

  constructor(props){
    super(props);
    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
   var url = "/epi/contest/"+props.slug;
    fetch(url,{
      method: "get",
      headers: h})
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({contest:result,form:{}});
          let doc = new DOMParser().parseFromString(this.state.body, 'text/html');
          this.description = doc.body.textContent.substring(0,240)+"..." || "";;
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    // if(!this.props.slug) return;

  }
}
export default ContestEntry
