/* eslint-disable */
import React, {Fragment} from "react";
import {Link} from "react-router-dom";

import { article } from "../../db/story";

// TODO: fix html parsing for body


class ArticleCard extends React.Component {

  state = article;

  render() {
    return (
      <Link to={"/music/article/"+this.props.article.slug}>
        <div className={"article-card "+(this.props.class ? this.props.class : '')}>
          <div

            className="article-card-img">
            <img
              src={"https://exclaim.ca" + this.props.article.photo.photo_file_name}
            />
            {this.props.ranked &&
            <div className="ranked-box">

            </div>
            }
          </div>
          <div className="article-card-title">
            <h4 >{this.props.article.title}</h4>
            { this.props.article.subtitle &&
            <h5 >{this.props.article.subtitle}</h5>
            }
          </div>
        </div>
      </Link>
    )
  }
}
export default ArticleCard
