/* eslint-disable */
import React from "react";
import {Link} from "react-router-dom"
import "../styles/TopBar.css";
class TopBar extends React.Component {
  render() {
    return (
      <div className="header">
          <div className="hamburger_wrapper">
            <a className="menu"><i className="material-icons" role="button"
              onClick={this.hamburgerToggle} id="menu-icon">menu</i></a>
            <Link to="/" className=" brand">Exclaim!</Link>
            <a className="search button"><i className="material-icons">search</i></a>
          </div>
          <div id="nav-menu">
            <ul className="nav-list">
              <li><Link to="/trending">Trending</Link></li>
              <li><Link to="/music">Music</Link></li>
              <li><Link to="/film">Film</Link></li>
              <li><Link to="/comedy">Comedy</Link></li>
              <li><Link to="/contest">Contests</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
        </div>
    )
  }

  hamburgerToggle(){
    document.getElementById('drawer').classList.toggle('hidden');
  }
}
export default TopBar
