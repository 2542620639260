/* eslint-disable */
import React from "react"
import { picks } from "../../db/picks";
import "../styles/Picks.css";


class Picks extends React.Component {
  state = picks;
  render() {
    return (
      <div className="picks">
        <a href={this.state[0].url.replace('https://exclaim.ca','')}>
          <div className="picks-card">
            <div className="picks-img">
              <a className="title-prev button fancy-button"><i className="material-icons">skip_previous</i></a>

              <img src={"https://exclaim.ca" + this.state[0].thumb}/>

              <a className="title-next button fancy-button"><i className="material-icons">skip_next</i></a>
            </div>
            <div className="picks-title">
              <h2>{this.state[0].headline}</h2>
            </div>
          </div>
        </a>
      </div>
    )
  }

  componentDidMount() {
    var h = new global.Headers({
     // "Authorization": "Token f439775cde934231771bc0d55b467dd6",
     "Content-Type": "application/json"
   });
   // var url = "https://media-pidgin.herokuapp.com/articles/"+this.props.slug;
   var url = "/picks";
    fetch(url,{
      method: "get",
      headers: h})
      .then(res => res.json())
      .then(
        (result) => {
          this.setState(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
}
export default Picks
